/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || ``} />
      <main style={{display: 'flex', flexGrow: 1}}>{children}</main>
      <footer style={{backgroundColor: '#1a535c'}}>
        <div style={{margin: `0 auto`, maxWidth: 960, padding: `0.5rem 0rem`, color: 'white', textAlign: 'center'}}>
          © {new Date().getFullYear()}, <a style={{color: 'white'}} href="https://monkeystation.nl">Monkey Station</a>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
